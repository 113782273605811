<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView">
      <div class="col-8">
        {{ object.item.name }}
      </div>
      <div class="col-4">
        {{ object.value}}
      </div>
    </div>

    <table v-if="state === 'activeRow' && object && object.id > 0" class="grey-bcg-ultralight full-width" v-on:click="state = 'activeFull'">
      <colgroup>
        <col v-for="index in cs" :style="'width:' + 100/cs + '%'" :key="index">
      </colgroup>
      <tr>
        <td :colspan="cs - 16">
          <div class="top-header" style="text-align: left">
            компонент
          </div>
          <div v-if="object && object.id > 0">
            {{ object.item.name }}
          </div>
        </td>
        <td :colspan="4">
          <div style="text-align: center">
            {{object.value >= 0 ? object.value : 0}}
          </div>
        </td>
        <td :colspan="4">
          <div style="text-align: center">
            {{object.reserved}}
          </div>
        </td>
        <td :colspan="4">
          <div style="text-align: center">
            {{object.inProduction}}
          </div>
        </td>
        <td :colspan="4">
          <div style="text-align: center" >
            {{object.requested > 0 ? object.requested : 0}}
          </div>
        </td>
      </tr>
    </table>


    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher">
      <div class="col-3">
        <a class="input-title">
          {{header ? header : 'Комплектация'}}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.item.name }}
      </div>
      <div class="col-9" v-else-if="create">
        Создать
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'activeFull'" class="full-width assembly-bcg-light" style="margin-top: -2px">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <br/>
              <full-view-header :header="'Компонент'" :select="select"
                                :historical="true"
                                @history="irc.controlHistory()"
                                @regularState="state = default_state ? default_state === 'activeFull' ? 'active' : default_state : start_state"
                                @editState="irc.startEdit('activeEdit')" @select="selectObject">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                      :start_state="'text'" :default_state="'text'"></item>
              </div>

              <div class="row">
                <row-up :header="'Норма расхода на единицу базового компонента:'" :value="object.quantity" :as-value="true" :col="6">Date</row-up>
                <row-up :header="'Потребность:'" :col="6" :value="object.value" :as-value="true">Value</row-up>
              </div>

              <div class="row assembly-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showAssembly" v-on:click="getAssembly">Компоненты</span>
                  <assemblies-table v-if="showAssembly" @element="hideElement('assembly')" :header="'Компоненты'"
                                    :downloading="downloading.assembly"
                                    :header-title="'Комплектующие изделия и материалы'"
                                    :objects="assemblies" :parent="asParent">Assemblies</assemblies-table>
                </div>
              </div>

              <div class="row enquire-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showEnquire" v-on:click="getEnquire">Заявки на закупку</span>
                  <enquiries-table v-if="showEnquire" @element="hideElement('enquire')" :header="'Заявки'"
                                   :downloading="downloading.enquiry"
                                   :header-title="'Заявки на закупку'"
                                   :objects="enquires" :parent="asParent">Enquiries</enquiries-table>
                </div>
              </div>

              <div class="row production-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showProduction" v-on:click="getProduction">Выпуск продукции</span>
                  <productions-table v-if="showProduction" @element="hideElement('production')" :header="'Производство'"
                                     :downloading="downloading.production"
                                     :header-title="'Выпуск компонентов'"
                                     :objects="productions" :parent="asParent">Assemblies</productions-table>
                </div>
              </div>

              <div class="row reserve-bcg margin-5">
                <div class="col-12 unselectable">
                  <span v-if="!showReserve" v-on:click="getReserve()">Резервы</span>
                  <reserve-table v-if="showReserve" @element="hideElement('reserve')" :header="'Резервы'"
                                 :downloading="downloading.reserve"
                                 :header-title="'Зарезервировано под компонент'"
                                 :objects="reserves" :parent="asParent"
                                 :group-by="'cell'">Reserves</reserve-table>
                </div>
              </div>
              <br/>

              <event-table v-if="irc.history.show && irc.history.data" :objects="irc.history.data"
                           :downloading="downloading.history"></event-table>

            </div>
          </div>
        </td>
      </tr>

    </table>

    <table v-if="state === 'full' && object && object.id > 0" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Компонент'" :select="select"
                                :historical="true"
                                @history="irc.controlHistory()"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="irc.startEdit()" @select="selectObject">
                Header
              </full-view-header>

              <div class="row item-bcg margin-for-inner-obj">
                <item :start_object="object.item" :header="'Номенклатура'"
                      :start_state="'text'" :default_state="'text'"></item>
              </div>

              <div class="row">
                <div class="col-3">
                  Норма расхода на единицу базового компонента:
                </div>
                <div class="col-9">
                  {{ object.quantity }}
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  Потребность:
                </div>
                <div class="col-9">
                  {{ object.value }}
                </div>
              </div>

              <div v-if="!select">

                <div class="row assembly-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showAssembly" v-on:click="getAssembly">Компоненты</span>
                    <assemblies-table v-if="showAssembly" @element="hideElement('assembly')" :header="'Компоненты'"
                                      :downloading="downloading.assembly"
                                      :header-title="'Комплектующие изделия и материалы'"
                                      :objects="assemblies" :parent="asParent">Assemblies</assemblies-table>
                  </div>
                </div>

                <div class="row enquire-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showEnquire" v-on:click="getEnquire">Заявки на закупку</span>
                    <enquiries-table v-if="showEnquire" @element="hideElement('enquire')" :header="'Заявки'"
                                     :downloading="downloading.enquiry"
                                     :header-title="'Заявки на закупку'"
                                     :objects="enquires" :parent="asParent">Enquiries</enquiries-table>
                  </div>
                </div>

                <div class="row production-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showProduction" v-on:click="getProduction">Выпуск продукции</span>
                    <productions-table v-if="showProduction" @element="hideElement('production')" :header="'Производство'"
                                       :downloading="downloading.production"
                                       :header-title="'Выпуск компонентов'"
                                       :objects="productions" :parent="asParent">Assemblies</productions-table>
                  </div>
                </div>

                <div class="row reserve-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showReserve" v-on:click="getReserve()">Резервы</span>
                    <reserve-table v-if="showReserve" @element="hideElement('reserve')" :header="'Резервы'"
                                   :downloading="downloading.reserve"
                                   :header-title="'Зарезервировано под компонент'"
                                   :objects="reserves" :parent="asParent"
                                   :group-by="'cell'">Reserves</reserve-table>
                  </div>
                </div>

                <event-table v-if="irc.history.show && irc.history.data" :objects="irc.history.data"
                             :downloading="downloading.history"></event-table>

              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'activeEdit' && object" class="full-width assembly-bcg-light">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="state = 'activeFull'">header</edit-header>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj">
                <item :start_object="object.item" :start_state="'text'" :default_state="'text'"
                      :select="true" @select="setItem" :header="'Номенклатура:'"> Item </item>
              </div>
            </div>
            <br>
            <div class="row">
              <input-row-up v-model="object.quantity" :header="'Норма расхода на единицу базовой продукции (' +
                    (object.plan ? object.plan.item.name : object.parent.item.name) + ')'" :number="true" class="full-width" :col="6"></input-row-up>
            </div>

            <edit-bottom v-if="!inSave" @fullView="state = 'activeFull'" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <div :key="selectZone">
              <div class="row margin-for-inner-obj">
                <item :start_object="object.item" :start_state="'text'" :default_state="'text'"
                         :select="true" @select="setItem" :header="'Номенклатура:'"> Sender </item>
              </div>
            </div>

            <input-row v-model="object.quantity" :header="'Норма расхода на единицу базовой продукции (' +
                    (object.plan ? object.plan.item.name : object.parent.item.name) + ')'" :number="true" class="full-width"></input-row>

            <edit-bottom v-if="!inSave" @fullView="fullView" @save="save">bottom</edit-bottom>

          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <assemblies-table v-bind:companies="selectPool" v-bind:header="'Комплектации'" @select="selectObjectFromTable"
                        :downloading="downloading.select"
                        @closeModal="closeModal"
                        v-bind:header-title="'Комплектующие, сборочные единицы и материалы'" :select="select"></assemblies-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {ASSEMBLY, ENQUIRE, ITEM, PRODUCTION, RESERVE} from "@/repository/repository";
import {ElementInterface} from "@/models/elementInteface";

export default {
  components: {
    CompaniesTable,
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    }
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      reserves: [],
      showReserve: false,
      assemblies: [],
      showAssembly: false,
      enquires: [],
      showEnquire: false,
      productions: [],
      showProduction: false,
      id: null,
      selectPool: [],
      asParent: {},
      selectZone: 0,
      inSave: false,
      viewKey: 0,
      flag: this.$flag,
      irc: null,
      showSelect: false,
      downloading: {state: false, select: {state: false}, reserve: {state: false}, assembly: {state: false},
        enquiry: {state: false}, production: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    create: false,
    header: null,
    cs: null
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else if (this.create && !this.object) {
        this.createModel();
      } else {
        this.fullView()
      }
    },
    async fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
        if (this.create) {
          this.object = null;
          this.state = 'text';
        }
      }
    },
    async createModel() {
      let url = 'assembly/model?type=' + this.parent.type + '&id=' + this.parent.id;
      let model = await this.$repo.justGetData(url, this.downloading);
      if (model.plan) {
        model.order = model.plan.order;
        model.item = model.plan.item;
      }
      this.object = model;
      this.viewKey++;
      this.state = 'edit';
    },
    async getEnquire() {
      this.enquires = [];
      this.showEnquire = true;
      await this.$repo.getList('assembly/enquire/' + this.object.id, ENQUIRE, this.enquires, {key: 'date', asc: false}, this.downloading.enquiry);
    },
    async getProduction() {
      this.productions = [];
      this.showProduction = true;
      await this.$repo.getList('assembly/production/' + this.object.id, PRODUCTION, this.productions, {key: 'date', asc: false}, this.downloading.production);
    },
    async getAssembly() {
      this.assemblies = [];
      this.showAssembly = true;
      await this.$repo.getList('assembly/content/' + this.object.id, ASSEMBLY, this.assemblies, {key: 'item.name', asc: true}, this.downloading.assembly);
    },
    async getReserve() {
      this.reserves = [];
      this.showReserve = true;
      await this.$repo.getList('assembly/reserve/' + this.object.id, RESERVE, this.reserves, {key: 'cell.item.name', asc: true}, this.downloading.reserve);
    },
    hideElement(element) {
      if (element === 'assembly') {
        this.showAssembly = false;
        this.assemblies = [];
      } else if (element === 'enquire') {
        this.showEnquire = false;
        this.enquires = [];
      } else if (element === 'reserve') {
        this.showReserve = false;
        this.reserves = [];
      } else if (element === 'production') {
        this.showProduction = false;
        this.productions = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setItem(item) {
      this.object.item = item;
      this.selectZone += 1;
    },
    setPlan(plan) {
      this.object.plan = plan;
      this.selectZone += 1;
    },
    setOrder(order) {
      this.object.order = order;
      this.selectZone += 1;
    },
    save() {
      this.$repo.uniSave(this, ASSEMBLY, 'assembly/', this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      if (!this.selectBounds) {
        await this.$repo.justGetList('document/',this.selectPool, null, this.downloading.select);
      } else {
        this.selectPool = await this.$repo.save('document/bounds/', ASSEMBLY, this.selectBounds, this.downloading.select);
      }
      this.callModal();
    },
    getBounds() {
      if (this.parent) {
        return [{includes: true, type: this.parent.type, id: this.parent.id}]
      } else {
        return undefined;
      }
    }
  },
  mounted: async function () {
    this.irc = new ElementInterface(this, ASSEMBLY, this.downloading.history);
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(ASSEMBLY, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.object && !this.create) {
      let url = 'assembly/model';
      if (this.parent) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.object = await this.$repo.getSingle(url, ASSEMBLY, this.downloading);
    }
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    this.asParent = {type: ASSEMBLY, id: this.object ? this.object.id : 0};
    if (this.state === 'active' && this.create) {
      await this.createModel();
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: ASSEMBLY, id: this.object.id};
      }
    },
    state: function (value) {
      this.$repo.watchState(this, ASSEMBLY, value);
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, ASSEMBLY, val);
    }
  }
}
</script>
<style>
</style>
